import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { LocationContext } from '../../context';

import Pdf from '../Pdf';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  tabPanel: {
    padding: theme.spacing(1),
    overflow: 'auto',
    flex: 1,
  },
}));

export default function MenuTabs() {
  const { selectedLocation } = useContext(LocationContext);

  const classes = useStyles();
  const [menus, setMenus] = useState([]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (selectedLocation?.menus?.length) {
      setMenus(selectedLocation.menus);
    }
  }, [selectedLocation]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {menus.map((item, idx) => (
            <Tab key={`tab-${idx}`} label={item?.name} {...a11yProps(idx)} />
          ))}
        </Tabs>
      </AppBar>
      {menus.map((item, idx) => (
        <TabPanel
          key={`tab-panel-${idx}`}
          className={classes.tabPanel}
          value={value}
          index={idx}
        >
          {item?.pdfUrl && <Pdf source={item?.pdfUrl} />}
        </TabPanel>
      ))}
    </div>
  );
}
